import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import { Link, useIntl } from "gatsby-plugin-intl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import Button from "components/atoms/Button"
import H from "components/atoms/H"
import P from "components/atoms/P"
import ArticleCard from "components/molecules/ArticleCard"
import SubscriptionForm from "components/molecules/SubscriptionForm"
import Modal from "components/atoms/Modal"
import paths from "utils/paths"
import theme from "styles/theme"
import {
  StyledSectionWrapper,
  StyledListWrapper,
  StyledHeadingWrapper,
  headingStyles,
  subHeadingStyles,
  mainTextStyles,
} from "./BlogList.styles"

const breakpointColumnsObj = {
  default: 2,
  [theme.breakpoints.medium]: 1,
}

const BlogList = ({ blogData }) => {
  const { formatMessage } = useIntl()
  const [isSubscriptionModalOpen, setSubscriptionModalStatus] = useState(false)
  const [visibleArticles, setVisibleArticles] = useState([])
  const [tempData] = useState([...blogData])
  useEffect(() => {
    setVisibleArticles(tempData.splice(0, 10))
  }, [blogData])

  const loadMore = () => {
    setVisibleArticles([...visibleArticles, ...tempData.splice(0, 10)])
  }

  const openSubscriptionModal = () => {
    setSubscriptionModalStatus(true)
  }
  const closeSubscriptionModal = () => {
    setSubscriptionModalStatus(false)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.SUBSCRIBE,
      action: "Click",
      label: `${trackingCategoriesHelper.SUBSCRIBE} button(Cancel)`,
    })
  }

  const handleTackCustomEventTrigger = (trackingOptions) => {
    if (trackingOptions) trackCustomEventTrigger(trackingOptions)
  }

  return (
    <>
      <StyledSectionWrapper>
        <StyledHeadingWrapper>
          <H as="h1" extraStyles={headingStyles}>
            {formatMessage({ id: "pages.blogListing.heading" })}
          </H>
        </StyledHeadingWrapper>
        <H as="h3" extraStyles={subHeadingStyles}>
          {formatMessage({ id: "pages.blogListing.subHeading" })}
        </H>
        <P extraStyles={mainTextStyles}>
          {formatMessage({ id: "pages.blogListing.text" })}
        </P>
        <Button
          onClick={openSubscriptionModal}
          data-testid="subscriptionModalButton"
          trackingOptions={{
            category: trackingCategoriesHelper.BLOG,
            action: "Click",
            label: `${trackingCategoriesHelper.BLOG} button(Subscribe)`,
          }}
        >
          {formatMessage({ id: "pages.blogListing.subscribe" })}
        </Button>
        <StyledListWrapper>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {visibleArticles.map((article) => (
              <Link
                to={`${paths.article(article.slug)}`}
                key={article.id}
                data-testid={`articleBlock${article.id}`}
                onClick={() => {
                  handleTackCustomEventTrigger({
                    category: trackingCategoriesHelper.BLOG,
                    action: "Click",
                    label: `${trackingCategoriesHelper.BLOG} Link(${article.slug})`,
                  })
                }}
              >
                <ArticleCard article={article} />
              </Link>
            ))}
          </Masonry>
        </StyledListWrapper>
        {Boolean(tempData.length) && (
          <Button
            onClick={loadMore}
            data-testid="loadMoreButton"
            trackingOptions={{
              category: trackingCategoriesHelper.BLOG,
              action: "Click",
              label: `${trackingCategoriesHelper.BLOG} button(Show more)`,
            }}
          >
            {formatMessage({ id: "pages.blogListing.loadMore" })}
          </Button>
        )}
      </StyledSectionWrapper>

      <Modal
        showModal={isSubscriptionModalOpen}
        handleCloseModal={closeSubscriptionModal}
      >
        <SubscriptionForm
          handleClose={closeSubscriptionModal}
          defaultProjectId="blog"
        />
      </Modal>
    </>
  )
}
BlogList.propTypes = {
  blogData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      articleTitle: PropTypes.string,
      slug: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      cardTeaser: PropTypes.string,
    })
  ).isRequired,
}
export default BlogList
