import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import ContactSection from "components/content/ContactSection"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import BlogList from "components/content/BlogList"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

const BlogPage = ({
  data: {
    api: { blog },
  },
  pageContext: { language },
}) => (
  <Layout language={language}>
    <SEO title="Blog" />
    <BlogList blogData={blog} />
    <ContactSection
      trackingOptions={{
        category: trackingCategoriesHelper.BLOG,
        action: "Click",
        label: `${trackingCategoriesHelper.BLOG} contact button(Send message)`,
      }}
    />
  </Layout>
)

BlogPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      blog: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          articleTitle: PropTypes.string,
          slug: PropTypes.string,
          image: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          cardTeaser: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export const query = graphql`
  query BlogPageData($apiLocale: API_Locale) {
    api {
      blog(language: $apiLocale) {
        id
        articleTitle
        image
        cardTeaser
        slug
      }
    }
  }
`

export default BlogPage
